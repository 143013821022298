import React, { FunctionComponent } from "react";
import { graphql } from "gatsby";
import PageLayout from "../components/page-layout/PageLayout";
import SEO from "../components/SEO";

type PrivacyTemplateProps = {
  pageContext: {
    html: string;
  };
};

const PrivacyTemplate: FunctionComponent<PrivacyTemplateProps> = ({
  pageContext,
}) => {
  return (
    <PageLayout contactForm={false}>
      <SEO noindex={true} />
      <section className="container px-5 my-12 mx-auto min-h-screen">
        <div
          className="prose"
          dangerouslySetInnerHTML={{ __html: pageContext.html }}
        ></div>
      </section>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PrivacyTemplate;
